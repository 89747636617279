import React, { useState } from "react";

import Illustration1 from "../../assets/images/about/about-illustration-1.png";
import Illustration2 from "../../assets/images/about/about-illustration-2.png";
import Illustration3 from "../../assets/images/about/about-illustration-3.png";
import Illustration4 from "../../assets/images/about/about-illustration-4.png";
import AboutImage from "../../assets/images/about/about-image.png";
import VideoCover from "../../assets/images/about/video-cover.png";

import borveyVideo from "../../assets/videos/borvey.mp4";

import style from "./about.module.scss";
import { PlayIcon } from "../../assets/icon";
import { AxiosRequest } from "../../utils/AxiosRequest";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const VideoComp = () => {
  const [isOn, setOn] = useState(false);
  return (
    <div
      onClick={() => setOn(!isOn)}
      className={`${style.videoWrapper} ${isOn ? style.zIndex : null}`}
    >
      {isOn ? (
        <video width="320" height="240" autoPlay>
          <source src={borveyVideo} type="video/mp4" />
          Your browser does not support the video tag
        </video>
      ) : (
        <div className={style.cover}>
          <button>
            <PlayIcon />
          </button>
          <img src={VideoCover} />
        </div>
      )}
    </div>
  );
};

const About = () => {
  const [data, setData] = useState();

  const getHomeData = () => {
    AxiosRequest("post", ApiRoutes.website.getHome)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHomeData();
  }, []);

  const aboutData = [
    {
      title: "Kullanıcı Dostu Platform",
      text: `Kullanıcı dostu platformumuzla gönderileri kolayca yönetin.`,
      illustration: Illustration1,
    },
    {
      title: "Ücretsiz İlan Verme Kolaylığı",
      text: `İster tek parça, ister ev ya da ofisiniz için ücretsiz ilan açmanız yeterli.`,
      illustration: Illustration2,
    },
    {
      title: "Güvenilir Nakliye Hizmetleri",
      text: `borvey güvenilir nakliye firmalarını sizin için bir araya getirir.`,
      illustration: Illustration3,
    },
    {
      title: "Hızlı Müşteri Desteği",
      text: `Bir sorunla karşılaştığınızda borvey’e hemen ulaşabilirsiniz.`,
      illustration: Illustration4,
    },
  ];

  return (
    <>
      {data ? (
        <Helmet>
          <title>{data.SEO.about.title}</title>
          <meta
            name="description"
            content={data.SEO.about.description}
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content={data.SEO.about.tags}
            data-react-helmet="true"
          />
        </Helmet>
      ) : null}
      <VideoComp />

      <div className={style.wrapper}>
        <div className={style.illustrationWrapper}>
          <h1>Bizi Farklı Kılan Nedir?</h1>
          <div className={style.cardWrapper}>
            {aboutData.map((item) => {
              return (
                <div className={style.card}>
                  <img src={item.illustration} />
                  <div className={style.textWrapper}>
                    <h5>{item.title}</h5>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={style.bottomPart}>
            <img src={AboutImage} />
            <h3>İster ev, ofis ister tek parça</h3>
            <p>
              Türkiye'nin her yerinde kullanabileceğiniz borvey'den hizmet almak
              için ücretsiz üyelik açmanız ve taşınma işlemlerinizin detaylarını
              içeren bir ilan bırakmanız yeterli.
              <br />
              <br />
              borvey üyesi nakliyat firmaları ilanınızı değerlendirir ve size
              tekliflerini iletir. Teklifler arasından dilediğinizi seçebilir ve
              taşınmanızı en uygun koşullarla gerçekleştirebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
