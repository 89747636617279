export const ApiRoutes = {
  auh: {
    login: "/auth/login",
    register: "/auth/register",
    resetPasswordEmail: "/auth/reset-password-email",
    resetPassword: "/auth/reset-password",
    checkEmail: "/auth/check-email",
    sendEmailAgain: "/auth/send-email-again",
    sendEmailForActive: "/auth/send-email-for-active",
  },
  user: {
    editUser: "/user/edit-user",
    getComments: "/user/get-comments",
    getWorks: "/user/get-works",
    createWork: "/user/create-work",
    deleteWork: "/user/delete-work",
    editWork: "/user/edit-work",
    getUser: "/user/get-user",
    editFirm: "/user/edit-firm",
    createMessage: "/user/create-message",
    changePassword: "/user/change-password",
    deleteUser: "/user/delete-user",
    getNotifications: "/user/get-notifications",
    readNotifications: "/user/read-notifications",
    reportMessage: "/user/report-message",
  },
  post: {
    getPostList: "/post/get-post-list",
    getPost: "/post/get-post",
    editPost: "/post/edit-post",
    createPost: "/post/create-post",
    getPostForService: "/post/get-post-for-service",
    createComment: "/post/create-comment",
    setPostStatus: "/post/set-post-status",
  },
  offer: {
    getOfferList: "/offer/get-offer-list",
    createOffer: "/offer/create-offer",
    responseOffer: "/offer/response-offer",
    myOfferlist: "/offer/get-my-offer-list",
  },
  message: {
    getMessageList: "/message/get-message-list",
  },
  website: {
    createContact: "/website/create-contact",
    getHome: "/website/get-home",
  },
};
